
import { computed } from "vue";
import { useRouter } from "@libraryHelpers/compositionApi";
import { useImageProperties } from "@libraryComposables/useImageProperties";
import { useSocialIcons } from "@libraryComposables/useSocialIcons";
import { renderData } from "@libraryHelpers/dataComponents.js";

export default {
	name: "CoreBlockContentCard",
	props: {
		settings: {
			type: Object,
			required: true,
		},
		dataSite: {
			type: Object,
			required: true,
		},
		isEditable: {
			type: Boolean,
			default: false,
		},
	},
	setup(props) {
		const router = useRouter();

		const { imageSrc: mediaSrc, imageAlt: mediaAlt } = useImageProperties(
			props,
			"media",
			null
		);

		const {
			blockFacebook,
			blockLinkedin,
			blockTwitter,
			blockYoutube,
			hasSocialIcons,
		} = useSocialIcons(props);

		const contentLibraryItems = computed(() => {
			return props.settings?.contentLibraryItems || [];
		});

		const overlineText = computed(() => {
			return props.settings?.overline
				? renderData(props.settings?.overline, props.dataSite)
				: null;
		});

		const headlineText = computed(() => {
			return props.settings?.headline
				? renderData(props.settings?.headline, props.dataSite)
				: null;
		});

		const bodyText = computed(() => {
			// Standardized name is 'bodyText', but some sites still have 'richText'
			const textValue =
				props.settings?.bodyText || props.settings?.richText;
			return textValue ? renderData(textValue, props.dataSite) : null;
		});

		const disclosureText = computed(() => {
			return props.settings?.disclosure
				? renderData(props.settings.disclosure, props.dataSite)
				: null;
		});

		const mediaMetadata = computed(() => {
			const background = props.settings?.media;
			const mediaSrc =
				background && typeof background === "string"
					? props.settings?.mediaMetadata?.media
					: props.settings?.media;
			if (!mediaSrc) return { src: background };
			const { crop } = mediaSrc;
			return { src: crop ? crop : mediaSrc };
		});

		const wrapText = computed(() => {
			return props.settings?.variants?.wrapText || false;
		});

		const mediaHeight = computed(() => {
			return props.settings?.mediaHeight || "100%";
		});

		const mediaWidth = computed(() => {
			return props.settings?.mediaWidth || "auto";
		});

		const hasButtons = computed(() => {
			return hasPrimaryButton.value || hasSecondaryButton.value;
		});

		const hasPrimaryButton = computed(() => {
			return props.settings?.primaryButton || false;
		});

		const hasSecondaryButton = computed(() => {
			return props.settings?.secondaryButton || false;
		});

		const secondaryButtonAsPrimary = computed(() => {
			return props.settings?.variants?.["secondary-as-primary"] || false;
		});

		const hasOnlyOneButton = computed(() => {
			return (
				(hasPrimaryButton.value || hasSecondaryButton.value) &&
				!(hasPrimaryButton.value && hasSecondaryButton.value)
			);
		});

		const imageLocation = computed(() => {
			return props.settings.variants["align-image"];
		});

		const contentCardClick = () => {
			if (hasOnlyOneButton.value) {
				const url = hasPrimaryButton.value
					? props.settings.primaryUrl
					: props.settings.secondaryUrl;
				const target = hasPrimaryButton.value
					? props.settings.primaryBtnNewWindow
					: props.settings.secondaryBtnNewWindow;
				url.startsWith("/")
					? openInternal(url)
					: openExternal(url, target);
			}
		};

		const openInternal = (link) => {
			router.push(link);
		};

		const openExternal = (link, target) => {
			window.open(link, target);
		};

		return {
			contentLibraryItems,
			overlineText,
			headlineText,
			bodyText,
			disclosureText,
			mediaSrc,
			mediaAlt,
			mediaMetadata,
			wrapText,
			mediaHeight,
			mediaWidth,
			hasButtons,
			hasPrimaryButton,
			hasSecondaryButton,
			secondaryButtonAsPrimary,
			hasOnlyOneButton,
			hasSocialIcons,
			blockFacebook,
			blockLinkedin,
			blockTwitter,
			blockYoutube,
			imageLocation,
			contentCardClick,
		};
	},
};
